import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhIconButton from "@components/buttons/BhIconButton";
import { useNavigate } from "react-router-dom";
import { EntityId } from "@reduxjs/toolkit";
import { useDocumentTitle } from "@/utilities/hooks/useDocumentTitle";
import { useTranslation } from "react-i18next";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import BhLightSeparatorVertical from "@components/separator/BhLightSeparatorVertical";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import {
  publishFormBaseVersionAsync,
  removeFormBaseVersionAsync,
  saveNewFormBaseVersionAsync,
  selectCompanyFormBaseById,
  selectIsFormBasePreviewEnabled,
  toggleFormBasePreview
} from "@/app/store/form/formBasesSlice";
import { FormBaseVersionStatus, IFormBaseVersion } from "@/model/form/IFormBaseVersion";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { useModal } from "react-modal-hook";
import BhFilterWithToggle from "@components/filters/BhFilterWithToggle";
import FormBaseHeaderPdfButton from "@components/form/base/header/FormBaseHeaderPdfButton";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faEye } from "@fortawesome/pro-regular-svg-icons/faEye";
import BhTag from "@components/tags/BhTag";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import FormBaseHeaderVersionDropdown from "@components/form/base/header/FormBaseHeaderVersionDropdown";
import { BhTagType } from "@components/tags/BhTagTypeEnum";

interface Props {
  companyId: EntityId;
  formBaseId: EntityId;
  formBaseVersionId: EntityId;
  projectId?: EntityId;
}

const FormBaseHeader: FC<Props> = ({ companyId, formBaseId, formBaseVersionId, projectId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const formBase = useAppSelector((state) => selectCompanyFormBaseById(state, formBaseId));
  const formBaseVersion = formBase && formBase.formBaseVersions && formBase.formBaseVersions.find((fbv) => fbv.id === formBaseVersionId);
  const lastFormBaseVersion = formBase && formBase.formBaseVersions && formBase.formBaseVersions.reduce((max, round) => (max.versionNumber > round.versionNumber ? max : round));
  const isDraft = formBaseVersion && formBaseVersion.status === FormBaseVersionStatus.DRAFT;
  const isFirstVersion = formBaseVersion && formBaseVersion.versionNumber === 1;
  const showPublishVersionButton = isDraft;
  const showNewVersionButton = !isDraft && lastFormBaseVersion && lastFormBaseVersion.status === FormBaseVersionStatus.FINAL && !formBase.bauhubFormBase;
  const showDeleteVersionButton = isDraft && !isFirstVersion;
  const isPreviewEnabled = useAppSelector(selectIsFormBasePreviewEnabled);
  const [isLoading, setIsLoading] = useState(false);

  const formName = formBase?.bauhubFormBase ? t("FORMS." + formBase?.type) : formBase?.type;
  useDocumentTitle(formName);

  useEffect(() => {
    if (formBase && formBase.bauhubFormBase) {
      dispatch(toggleFormBasePreview(true));
    }
  }, [formBase]);

  const [showDeleteChecklistBaseVersionConfirmationModal, hideDeleteChecklistBaseVersionConfirmationModal] = useModal(
    () => (
      <BhDeleteConfirmationModal
        setIsOpen={hideDeleteChecklistBaseVersionConfirmationModal}
        header={<h2>{t("CHECKLIST.VERSION.DELETE.MODAL.HEADER")}</h2>}
        body={<div>{t("CHECKLIST.VERSION.DELETE.MODAL.BODY")}</div>}
        confirmationButtonText={t("GLOBAL.DELETE")}
        handleDelete={() => {
          deleteChecklistBaseVersion();
          hideDeleteChecklistBaseVersionConfirmationModal();
        }}
      />
    ),
    [formBaseVersionId]
  );

  const goBack = () => {
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + (projectId ? `/project/${projectId}/forms/settings` : `/company/${companyId}/forms`));
  };

  const deleteChecklistBaseVersion = () => {
    const previousVersion = formBase && formBase.formBaseVersions && formBase.formBaseVersions.find((fbv) => formBaseVersion && fbv.versionNumber === formBaseVersion.versionNumber - 1);
    if (previousVersion && formBaseVersion) {
      dispatch(removeFormBaseVersionAsync({ companyId: companyId, formBaseId: formBase.id, formBaseVersionId: formBaseVersion.id }));
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + (projectId ? `/project/${projectId}` : `/company/${companyId}`) + `/form/base/${formBase.id}/version/${previousVersion.id}`);
    }
  };

  const publishFormBaseVersion = () => {
    dispatch(publishFormBaseVersionAsync({ companyId: companyId, formBaseId: formBaseId, formBaseVersionId: formBaseVersionId }));
  };

  const addNewVersion = () => {
    if (isLoading || !formBase) return;
    setIsLoading(true);
    dispatch(saveNewFormBaseVersionAsync({ companyId: companyId, formBaseId: formBase.id }))
      .then((action) => {
        const newFormBaseVersion = action.payload as IFormBaseVersion;
        navigate(
          ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + (projectId ? `/project/${projectId}` : `/company/${companyId}`) + `/form/base/${formBase.id}/version/${newFormBaseVersion.id}`
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="flew-row bh-border-pigeon-20 flex w-full items-center justify-between border-b-8 p-4 pr-7">
      <div className="flex flex-col pl-2">
        <div className="text-12px bh-text-pigeon ml-[122px] font-bold">{t("FORMBUILDER.FORM.BASE.NEW_FORM_BASE")}</div>
        {formBase && formBaseVersion && (
          <div className="flex flex-row items-center gap-x-3">
            <BhIconButton icon={faArrowLeft} buttonProps={{ onClick: goBack }} />
            <FormBaseHeaderVersionDropdown formBaseVersions={formBase.formBaseVersions} currentVersionId={formBaseVersion.id} companyId={companyId} projectId={projectId} />
            <h2 className="m-0">{formBase.bauhubFormBase ? t("FORMS." + formBase.type) : formBase.type}</h2>
            <BhTag type={isDraft ? BhTagType.MUSTAND : BhTagType.TAG} children={t("CHECKLIST.STATUS." + formBaseVersion.status.toUpperCase())} />
          </div>
        )}
        {(!formBase || !formBaseVersion) && <div className="bh-bg-pigeon-50 ml-[122px] h-9 w-52 animate-pulse rounded"></div>}
      </div>
      <div className="mb-2 flex h-full flex-row items-end">
        {showDeleteVersionButton && (
          <div className="flex flex-row items-center">
            <BhIconButton icon={faTrash} buttonProps={{ onClick: showDeleteChecklistBaseVersionConfirmationModal }} />
            <div className="h-6 pl-1 pr-4">
              <BhLightSeparatorVertical />
            </div>
          </div>
        )}
        {formBase && !formBase.bauhubFormBase && (
          <div className="flex flex-row items-center">
            <BhFilterWithToggle text={t("FORMBUILDER.FORM.BASE.PREVIEW")} value={isPreviewEnabled} onClickAction={() => dispatch(toggleFormBasePreview(!isPreviewEnabled))} />
            <div className="h-6 pl-4 pr-4">
              <BhLightSeparatorVertical />
            </div>
          </div>
        )}
        <FormBaseHeaderPdfButton companyId={companyId} formBaseId={formBaseId} formBaseVersionId={formBaseVersionId} formType={formBase?.type}>
          <BhSecondaryButton icon={faEye}>{"PDF"}</BhSecondaryButton>
        </FormBaseHeaderPdfButton>
        {showPublishVersionButton && <BhPrimaryButton buttonProps={{ onClick: publishFormBaseVersion }}>{t("CHECKLIST.PUBLISH")}</BhPrimaryButton>}
        {showNewVersionButton && (
          <BhPrimaryButton icon={faPlus} buttonProps={{ onClick: addNewVersion }}>
            {t("CHECKLIST.BASE.NEW_VERSION")}
          </BhPrimaryButton>
        )}
      </div>
    </div>
  );
};

export default FormBaseHeader;
