import React, { FC, useCallback } from "react";
import BhInputWithFetchedUsers from "@components/input/BhInputWithFetchedUsers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import BhInputSimple from "@components/input/BhInputSimple";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import BhDatePicker from "@components/input/BhDatePicker";
import { classNames } from "@/utilities/jsUtilities";
import BhIconButton from "@components/buttons/BhIconButton";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import { IFormDataSaveRequest } from "@/model/IForm";

interface Props {
  person: any;
  fieldProperty: string;
  enabledColumns: Array<string>;
  disabled?: boolean;
  saveCallback?: Function;
  removeNestedDataCallback?: Function;
}

const FormPeopleFieldTableRow: FC<Props> = ({ person, fieldProperty, enabledColumns, disabled, saveCallback, removeNestedDataCallback }) => {
  const textInputProperties = ["occupation", "company", "email", "num", "custom", "qualification", "firm"];
  const checkboxInputProperties = ["isSigner", "status"];

  const selectUserFromDropdown = useCallback((value: any, changedProperty: any) => {
    let changedValue = value;
    if (!value.name.username) {
      savePersonField(value, changedProperty);
      return;
    }
    const isValueObjectIncludingCompanyName = value.name && value.name.simpleUserInfo && enabledColumns.includes("company");
    const isValueObjectIncludingOccupation = value.name && value.name.simpleUserInfo && enabledColumns.includes("occupation");
    const isValueObjectIncludingFirm = value.name && value.name.simpleUserInfo && enabledColumns.includes("firm");
    const isValueObjectIncludingEmail = value.name && value.name.username && enabledColumns.includes("email");
    const isValueObjectIncludingPhone = value.name && value.name.simpleUserInfo && enabledColumns.includes("num");
    if (isValueObjectIncludingCompanyName) {
      changedValue = { ...changedValue, ...{ company: value.name.simpleUserInfo.companyName } };
    }
    if (isValueObjectIncludingFirm) {
      changedValue = { ...changedValue, ...{ firm: value.name.simpleUserInfo.companyName } };
    }
    if (isValueObjectIncludingOccupation) {
      changedValue = { ...changedValue, ...{ occupation: value.name.simpleUserInfo.title } };
    }
    if (isValueObjectIncludingEmail) {
      changedValue = { ...changedValue, ...{ email: value.name.username } };
    }
    if (isValueObjectIncludingPhone) {
      changedValue = { ...changedValue, ...{ num: value.name.simpleUserInfo.contactNumber } };
    }
    const saveRequest = {
      path: fieldProperty,
      changes: { ...person, ...changedValue },
      changedObjectId: person._id
    } as IFormDataSaveRequest;
    saveCallback && saveCallback(saveRequest);
  }, []);

  const savePersonField = useCallback((changedObject: any, changedProperty: any) => {
    const changedValue = changedProperty === "status" ? (Boolean(changedObject[changedProperty]) ? "ATTENDED" : "MISSED") : changedObject[changedProperty];
    const saveRequest = {
      path: fieldProperty,
      changedProperty: changedProperty,
      changedValue: changedValue,
      changedObjectId: person._id
    } as IFormDataSaveRequest;
    saveCallback && saveCallback(saveRequest);
  }, []);

  const removePerson = (person: any) => {
    removeNestedDataCallback && removeNestedDataCallback({ _id: person._id }, fieldProperty);
  };

  return (
    <div className="mb-2 flex w-full flex-row items-center gap-x-2">
      {enabledColumns.map((column, index) => {
        return (
          <div
            key={index}
            className={classNames("form-people-field-width flex flex-row items-center justify-center", column === "isSigner" || column === "status" ? "w-24 min-w-24 max-w-24" : "w-full")}
          >
            {column === "name" && (
              <div className="relative w-full">
                <BhInputWithFetchedUsers
                  initialValue={person.name}
                  property="name"
                  saveCallback={(value: any) => {
                    selectUserFromDropdown(value, column);
                  }}
                  disabled={disabled}
                />
                <FontAwesomeIcon icon={faUser} className="bh-text-deep-ocean-80 absolute right-2 top-3" />
              </div>
            )}
            {textInputProperties.includes(column) && <BhInputSimple initialValue={person[column]} property={column} saveCallback={(val: any) => savePersonField(val, column)} disabled={disabled} />}
            {checkboxInputProperties.includes(column) && (
              <div className="flex w-24 min-w-24 max-w-24 flex-row justify-center">
                <BhCheckbox
                  isChecked={column === "status" ? person[column] === "ATTENDED" : person[column]}
                  large={true}
                  onChange={(val: any) => savePersonField(val, column)}
                  property={column}
                  disabled={disabled}
                />
              </div>
            )}
            {column === "date" && (
              <BhDatePicker
                property={column}
                disabled={disabled}
                initialValue={new Date(person.date)}
                iconVisible={true}
                returnISOString={true}
                onChangeCallback={(val: any) => savePersonField(val, column)}
                widthClasses={"w-full"}
                wrapperClasses={"w-full"}
              />
            )}
          </div>
        );
      })}
      <div className="w-10 min-w-10 max-w-10">{!disabled && <BhIconButton icon={faXmark} buttonProps={{ onClick: () => removePerson(person), disabled: disabled }} />}</div>
    </div>
  );
};

export default FormPeopleFieldTableRow;
