import { createAsyncThunk, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { IForm, IFormDataSaveRequest, IFormDiff, IFormDTO, IFormImportInfo, IFormTemplate, ISimpleForm } from "@/model/IForm";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import {
  addNestedFormData,
  fetchFieldDataOfSameTypeFormsForFormFieldDateRange,
  fetchForm,
  fetchFormDTO,
  prefillFormFromPreviousForm,
  removeNestedFormData,
  saveFormData,
  saveFormEditor,
  saveFormLocked,
  saveFormName,
  saveFullForm,
  saveNestedFormData,
  saveNewForm,
  savePartForm
} from "@/api/form/formAPI";
import { FormModalsInitialState, IFormModals, IFormModalsOpen } from "@/model/form/IFormModals";
import * as jsonpatch from "fast-json-patch";
import { IFormEditorInfo } from "@/model/IFormEditor";
import { selectCurrentUser, selectIsCurrentUserProjectAdmin } from "@/app/store/userSlice";
import { bauhubToString } from "@/utilities/jsUtilities";

export interface IFormState {
  status: BhStateStatusType;
  current: IFormDTO;
  formModals: IFormModals;
  formEdit: Array<IFormTemplate>;
  fileIdToRenameInForm?: EntityId;
  loadingElements: { [key: string]: boolean };
}

const initialState: IFormState = {
  status: BhStateStatusType.INITIAL,
  current: {} as IFormDTO,
  formModals: FormModalsInitialState,
  formEdit: [] as Array<IFormTemplate>,
  loadingElements: {}
};

export const fetchFormAsync = createAsyncThunk("form/fetchForm", async (formInfo: { projectId: EntityId; dirId: EntityId; fileEntityId: EntityId }) => {
  return fetchForm(formInfo.projectId, formInfo.dirId, formInfo.fileEntityId);
});
export const saveFullFormAsync = createAsyncThunk("form/saveFullForm", async (formInfo: { projectId: EntityId; dirId: EntityId; formToSave: IForm }) => {
  return saveFullForm(formInfo.projectId, formInfo.dirId, formInfo.formToSave);
});
export const savePartFormAsync = createAsyncThunk("form/savePartForm", async (formInfo: { projectId: EntityId; dirId: EntityId; formDiff: IFormDiff }) => {
  return savePartForm(formInfo.projectId, formInfo.dirId, formInfo.formDiff);
});
export const fetchFieldDataOfSameTypeFormsForFormFieldDateRangeAsync = createAsyncThunk("form/modal/import", async (formImportInfo: IFormImportInfo) => {
  return fetchFieldDataOfSameTypeFormsForFormFieldDateRange(formImportInfo);
});
export const fetchFormDTOAsync = createAsyncThunk("form/fetchFormDTO", async (dto: { dirId: EntityId; fileEntityId: EntityId; projectId: EntityId }) => {
  return fetchFormDTO(dto.dirId, dto.fileEntityId);
});
export const saveFormDataAsync = createAsyncThunk("form/data/save", async (request: IFormDataSaveRequest, { getState }) => {
  const state: RootState = getState() as RootState;
  const saveRequest = { ...request, formUpdated: state.form.current.form.updated } as IFormDataSaveRequest;
  return saveFormData(saveRequest);
});
export const saveNestedFormDataAsync = createAsyncThunk("form/data/nested/save", async (request: IFormDataSaveRequest, { getState, dispatch }) => {
  const state: RootState = getState() as RootState;
  const saveRequest = { ...request, formUpdated: state.form.current.form.updated } as IFormDataSaveRequest;
  dispatch(setNestedFormData(saveRequest));
  const result = await saveNestedFormData(saveRequest);
  dispatch(setNestedFormData(result));
  dispatch(setElementLoading({ id: request.changedObjectId, isLoading: false }));
  return result;
});
export const addNestedFormDataAsync = createAsyncThunk("form/data/nested/add", async (request: IFormDataSaveRequest, { getState }) => {
  const state: RootState = getState() as RootState;
  const saveRequest = { ...request, formUpdated: state.form.current.form.updated } as IFormDataSaveRequest;
  return addNestedFormData(saveRequest);
});
export const removeNestedFormDataAsync = createAsyncThunk("form/data/nested/remove", async (request: IFormDataSaveRequest, { getState }) => {
  const state: RootState = getState() as RootState;
  const saveRequest = { ...request, formUpdated: state.form.current.form.updated } as IFormDataSaveRequest;
  return removeNestedFormData(saveRequest);
});
export const saveNewFormAsync = createAsyncThunk("form/new/save", async (formInfo: { projectId: EntityId; dirId: EntityId; formToSave: IForm }) => {
  return saveNewForm(formInfo.projectId, formInfo.dirId, formInfo.formToSave);
});
export const saveFormNameAsync = createAsyncThunk("form/name/save", async (formInfo: { projectId: EntityId; formFileEntityId: EntityId; formName: string }) => {
  return saveFormName(formInfo.projectId, formInfo.formFileEntityId, formInfo.formName);
});
export const prefillFormFromPreviousFormAsync = createAsyncThunk("form/prefill/from/previous", async (dto: { formId: EntityId; selectedForm: ISimpleForm }) => {
  return prefillFormFromPreviousForm(dto.formId, dto.selectedForm);
});
export const saveFormLockedAsync = createAsyncThunk("form/locked", async (dto: { projectId: EntityId; formId: EntityId; locked: boolean }) => {
  return saveFormLocked(dto.projectId, dto.formId, dto.locked);
});
export const saveFormEditorAsync = createAsyncThunk("form/editor/change", async (dto: { projectId: EntityId; formEditorInfo: IFormEditorInfo }) => {
  return saveFormEditor(dto.projectId, dto.formEditorInfo);
});

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    toggleFormModalsOpen: (state, action: PayloadAction<{ modal: keyof IFormModalsOpen }>) => {
      state.formModals.open[action.payload.modal] = !state.formModals.open[action.payload.modal];
    },
    setFormModalsField: (state, action) => {
      state.formModals.field = action.payload;
    },
    setNestedFormData: (state, action: PayloadAction<IFormDataSaveRequest>) => {
      if (action.payload.path) {
        state.current.form.data[action.payload.path] = state.current.form.data[action.payload.path].map((obj: any) => {
          if (obj._id === action.payload.changedObjectId) {
            if (action.payload.changedProperty) {
              return { ...obj, ...{ [action.payload.changedProperty]: action.payload.changedValue } };
            }
            if (action.payload.changes) {
              return action.payload.changes;
            }
          }
          return obj;
        });
      }
    },
    setFormModalsData: (state, action) => {
      state.formModals.data = action.payload;
    },
    toggleFormModalsAttachmentField: (state, action: PayloadAction<boolean>) => {
      state.formModals.isAttachmentsField = action.payload;
    },
    resetFormModalsData: (state) => {
      delete state.formModals.data;
      delete state.formModals.field;
    },
    resetCurrentForm: (state) => {
      state.current = {} as IFormDTO;
    },
    addNewValueToCurrentFormDataArray: (state, action: PayloadAction<{ formDataProperty: string; value: any }>) => {
      state.current.form.data[action.payload.formDataProperty] = [...state.current.form.data[action.payload.formDataProperty], action.payload.value];
    },
    setFileIdToRenameInForm: (state, action) => {
      state.fileIdToRenameInForm = action.payload;
    },
    setElementLoading: (state, action) => {
      const { id, isLoading } = action.payload;
      if (isLoading) {
        state.loadingElements[id] = true; // Add to Object
      } else {
        delete state.loadingElements[id]; // Remove from Object
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchFormAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        // state.current = action.payload;
      })
      .addCase(saveFullFormAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveFullFormAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        // state.current = action.payload;
      })
      .addCase(savePartFormAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(savePartFormAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const formDiffResult = action.payload;
        state.current.form.data = jsonpatch.applyPatch(state.current.form.data, formDiffResult.dataChanges).newDocument;
        state.current.form.updated = formDiffResult.updated;
        state.current.form.name = formDiffResult.name;
      })
      .addCase(fetchFieldDataOfSameTypeFormsForFormFieldDateRangeAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchFieldDataOfSameTypeFormsForFormFieldDateRangeAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.formModals.data = action.payload;
      })
      .addCase(fetchFormDTOAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchFormDTOAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.current = action.payload;
      })
      .addCase(saveFormDataAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveFormDataAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.current.form.updated = action.payload.formUpdated;
        if (action.payload.formDataUpdatedByAnotherUser) {
          state.current.form.data = action.payload.formDataUpdatedByAnotherUser;
        }
        if (action.payload.path) {
          const parts = action.payload.path.split(".");
          state.current.form.data[parts[0]][parts[1]] = action.payload.changes.value;
          return;
        }
        state.current.form.data = { ...state.current.form.data, ...action.payload.changes };
      })
      .addCase(saveNestedFormDataAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveNestedFormDataAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.current.form.updated = action.payload.formUpdated;
        if (action.payload.formDataUpdatedByAnotherUser) {
          state.current.form.data = action.payload.formDataUpdatedByAnotherUser;
        }
      })
      .addCase(addNestedFormDataAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(addNestedFormDataAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.current.form.updated = action.payload.formUpdated;
        if (action.payload.formDataUpdatedByAnotherUser) {
          state.current.form.data = action.payload.formDataUpdatedByAnotherUser;
        }
        if (action.payload.path) {
          state.current.form.data[action.payload.path] = state.current.form.data[action.payload.path] || [];
          if (action.payload.index || action.payload.index === 0) {
            state.current.form.data[action.payload.path].splice(action.payload.index, 0, action.payload.changes);
            return;
          }
          state.current.form.data[action.payload.path].push(action.payload.changes);
        }
      })
      .addCase(removeNestedFormDataAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(removeNestedFormDataAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.current.form.updated = action.payload.formUpdated;
        if (action.payload.formDataUpdatedByAnotherUser) {
          state.current.form.data = action.payload.formDataUpdatedByAnotherUser;
        }
        if (action.payload.path) {
          state.current.form.data[action.payload.path] = state.current.form.data[action.payload.path].filter((obj: any) => {
            return obj._id !== action.payload.changes._id;
          });
        }
      })
      .addCase(saveNewFormAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveNewFormAsync.fulfilled, (state) => {
        state.status = BhStateStatusType.SUCCESS;
      })
      .addCase(saveFormNameAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveFormNameAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.current.form.name = action.payload.name;
      })
      .addCase(prefillFormFromPreviousFormAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(prefillFormFromPreviousFormAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.current.form.data = action.payload;
      })
      .addCase(saveFormLockedAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveFormLockedAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.current.form.locked = action.payload.locked;
      })
      .addCase(saveFormEditorAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveFormEditorAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.current.form.editors = action.payload.editors;
      });
  }
});

export const {
  toggleFormModalsOpen,
  setFormModalsField,
  setNestedFormData,
  toggleFormModalsAttachmentField,
  resetFormModalsData,
  setFormModalsData,
  resetCurrentForm,
  addNewValueToCurrentFormDataArray,
  setFileIdToRenameInForm,
  setElementLoading
} = formSlice.actions;

export const selectCurrentForm = (state: RootState) => state.form.current.form;
export const selectCurrentFormData = (state: RootState) => state.form.current && state.form.current.form && state.form.current.form.data;
export const selectIsAttachmentsField = (state: RootState) => state.form.formModals.isAttachmentsField;
export const selectCurrentFormId = (state: RootState) => state.form.current.form && state.form.current.form.id;
export const selectCurrentFormFileEntityId = (state: RootState) => state.form.current.form && state.form.current.form.fileEntityId;
export const selectCurrentFormType = (state: RootState) => state.form.current.form && state.form.current.form.formType;
export const selectCurrentFormName = (state: RootState) => state.form.current.form && state.form.current.form.name;
export const selectIsCurrentFormLocked = (state: RootState) => state.form.current.form && state.form.current.form.locked;
export const selectCurrentFormEditors = (state: RootState) => state.form.current.form && state.form.current.form.editors;
export const selectCurrentFormBase = (state: RootState) => state.form.current && state.form.current.formBase;
export const selectCurrentFormRows = (state: RootState) => state.form.current.rows;
export const selectIsElementLoading = (state: RootState, id: string) => state.form.loadingElements[id];
export const selectCurrentFormRowsSorted = createSelector(selectCurrentFormRows, (rows) =>
  rows.slice().sort((a, b) => {
    return a.orderNumber < b.orderNumber ? -1 : 1;
  })
);
export const selectIsCurrentUserFormCreator = createSelector([selectCurrentUser, selectCurrentForm], (currentUser, currentForm) => {
  return currentForm && currentUser.username.toLowerCase() === currentForm.createdBy.toLowerCase();
});
export const selectCanCurrentUserEditForm = createSelector(
  [selectCurrentUser, selectCurrentForm, selectIsCurrentUserFormCreator, selectIsCurrentFormLocked, selectIsCurrentUserProjectAdmin],
  (currentUser, currentForm, isCurrentUserFormCreator, isFormLocked, isCurrentUserProjectAdmin) => {
    if (!isFormLocked || isCurrentUserProjectAdmin) {
      return true;
    }
    const isCurrentUserFormEditor = currentForm && currentForm.editors && currentForm.editors.some((editor) => editor.id === currentUser.id);
    return isCurrentUserFormCreator || isCurrentUserFormEditor;
  }
);
export const selectImportFieldInfoModalOpen = (state: RootState) => state.form.formModals.open.importFieldInfoModal;
export const selectImportFieldInfoModalData = (state: RootState) => state.form.formModals.data;
export const selectImportFieldInfoModalField = (state: RootState) => state.form.formModals.field;
export const selectValueByPropertyFromCurrentFormData = createSelector([selectCurrentFormData, (state: any, property: string) => property], (currentFormData, property) => {
  if (!currentFormData || !currentFormData[property]) return null;
  return currentFormData[property];
});
export const selectProtocolRowIdsFromCurrentFormData = createSelector([selectCurrentFormData, (state: any, property: string) => property], (currentFormData, property) => {
  if (!currentFormData || !currentFormData[property]) {
    return [];
  }
  return currentFormData[property].map((row: any) => row._id);
});

export const selectRowId = (state: RootState, rowId: EntityId) => rowId;
export const selectProperty = (state: RootState, _: any, property: string) => property;
export const selectProtocolRowByIdFromCurrentFormData = createSelector([selectCurrentFormData, selectRowId, selectProperty], (currentFormData, rowId, property) => {
  if (!currentFormData || !currentFormData[property]) return null;
  return currentFormData[property].find((row: any) => row._id === rowId);
});
export const selectIsOnlyOneMainRowRemaining = createSelector([selectCurrentFormData, selectRowId, selectProperty], (currentFormData, rowId, property) => {
  return currentFormData && currentFormData[property] && currentFormData[property].length > 0 && currentFormData[property].filter((row: any) => row.level === 0).length === 1;
});
export const selectIndexForNextProtocolMainRowByCurrentRowId = createSelector([selectCurrentFormData, selectRowId, selectProperty], (currentFormData, rowId, property) => {
  if (!currentFormData || !currentFormData[property]) return null;
  const row = currentFormData[property].find((row: any) => row._id === rowId);
  if (row) {
    const agenda = currentFormData[property];
    const indexOfRow = agenda.indexOf(row);
    let insertIntoIndex = 0;
    for (let i = indexOfRow; i < agenda.length; i++) {
      if (!agenda[i + 1] || agenda[i + 1].level !== 1) {
        insertIntoIndex = i + 1;
        break;
      }
    }
    return insertIntoIndex;
  }
});
export const selectIndexForNextProtocolSubRowByCurrentRowId = createSelector([selectCurrentFormData, selectRowId, selectProperty], (currentFormData, rowId, property) => {
  if (!currentFormData || !currentFormData[property]) return null;
  const row = currentFormData[property].find((row: any) => row._id === rowId);
  if (row) {
    const indexOfRow = currentFormData[property].indexOf(row);
    return indexOfRow + 1;
  }
});
export const selectMntTableSumRowValuesByFormType = createSelector([selectCurrentFormData, (state: any, formType: string) => formType], (currentFormData, formType) => {
  const tableSumObjectArrayMap: Record<string, Array<any>> = {
    MNT_MP_ALUSED: [{ sumColumn: "q", sumProperty: "sumQ" }],
    MNT_MP_DREEN: [{ sumColumn: "q", sumProperty: "sumQ" }],
    MNT_MP_KATEND: [{ sumColumn: "o", sumProperty: "sumO" }],
    MNT_MP_KRAAVID: [{ sumColumn: "n", sumProperty: "sumN" }],
    MNT_MP_MULD: [{ sumColumn: "p", sumProperty: "sumP" }],
    MNT_MP_PINNAS: [
      { sumColumn: "f", sumProperty: "sumF" },
      { sumColumn: "g", sumProperty: "sumG" },
      { sumColumn: "h", sumProperty: "sumH" },
      { sumColumn: "i", sumProperty: "sumI" },
      { sumColumn: "j", sumProperty: "sumJ" }
    ]
  };
  if (!tableSumObjectArrayMap[formType] || !currentFormData) {
    return;
  }
  const dataTable = currentFormData.dataTable;
  const sumObjectArray = tableSumObjectArrayMap[formType];
  let tableSumObject = {} as any;
  if (sumObjectArray.length > 0) {
    sumObjectArray.forEach((sumObject: any) => {
      let sum = 0;
      if (dataTable.length > 0) {
        dataTable.forEach((row: any) => {
          if (row[sumObject.sumColumn]) {
            var parsed = parseFloat(bauhubToString(row[sumObject.sumColumn]).replace(",", "."));
            if (!isNaN(parsed)) {
              sum += parsed;
            }
          }
        });
      }
      tableSumObject[sumObject.sumProperty] = sum;
    });
  }
  return tableSumObject;
});
export const selectTooplaanWorksDateRange = createSelector([selectCurrentFormData], (currentFormData) => {
  if (!currentFormData) return null;
  const weeks = currentFormData.weeks;
  if (weeks.length > 0) {
    const earliestYear = weeks.reduce((min: any, week: any) => (min.info.year < week.info.year ? min : week)).info.year;
    const earliestYearWeeks = weeks.filter((week: any) => week.info.year === earliestYear);
    const earliestWeek = earliestYearWeeks.reduce((min: any, week: any) => (min.info.number < week.info.number ? min : week));
    const latestYear = weeks.reduce((max: any, week: any) => (max.info.year > week.info.year ? max : week)).info.year;
    const latestYearWeeks = weeks.filter((week: any) => week.info.year === latestYear);
    const latestWeek = latestYearWeeks.reduce((max: any, week: any) => (max.info.number > week.info.number ? max : week));
    return earliestWeek.info.dateRange.split(" - ")[0] + "." + earliestYear.toString() + " - " + latestWeek.info.dateRange.split(" - ")[1] + "." + latestYear.toString();
  } else {
    return "";
  }
});

export default formSlice.reducer;
