import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhModal from "@components/modal/BhModal";
import { IWorkGroup } from "@/model/IWorkGroup";
import { IMaruValuation } from "@/model/maru/IMaruValuation";
import { fetchMaruPrefilledValuation } from "@/api/maru/maruAPI";
import BhTextareaStackedLabel from "@components/input/BhTextareaStackedLabel";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import BhDatepickerStackedLabel from "@components/input/BhDatepickerStackedLabel";
import BhStarRatingStackedLabel from "@components/input/BhStarRatingStackedLabel";
import { useAppDispatch } from "@/app/hooks";
import { saveMaruWorkGroupValuationAsync } from "@/app/store/project/projectWorkGroupsSlice";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { useTranslation } from "react-i18next";

interface Props {
  workGroup: IWorkGroup;
  setIsShown: Dispatch<SetStateAction<boolean>>;
}

const MaruPartyValuationModal: FC<Props> = ({ workGroup, setIsShown }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [maruValuation, setMaruValuation] = useState({} as IMaruValuation);
  const [valuationAverageScore, setValuationAverageScore] = useState(0);
  const [formDirty, setFormDirty] = useState(false);

  useEffect(() => {
    fetchMaruPrefilledValuation(workGroup.id).then((valuation) => {
      setMaruValuation(valuation);
    });
  }, [workGroup]);

  useEffect(() => {
    setValuationAverageScore(calculateAverageScore());
  }, [maruValuation]);

  const requiredFieldsUnfilled = !maruValuation.contactname || !maruValuation.projectstartdate || !maruValuation.projectenddate;

  const calculateAverageScore = () => {
    const qualityRate = maruValuation.qualityrate ? parseInt(maruValuation.qualityrate.toString(), 10) : 0;
    const termRate = maruValuation.termrate ? parseInt(maruValuation.termrate.toString(), 10) : 0;
    const collaborationRate = maruValuation.collaborationrate ? parseInt(maruValuation.collaborationrate.toString(), 10) : 0;
    const abilitiesRate = maruValuation.abilitiesrate ? parseInt(maruValuation.abilitiesrate.toString(), 10) : 0;
    return (qualityRate + termRate + collaborationRate + abilitiesRate) / 4;
  };

  const saveValuation = () => {
    if (requiredFieldsUnfilled) {
      return;
    }
    const valuationToSave = { ...maruValuation, averagerate: valuationAverageScore };
    dispatch(saveMaruWorkGroupValuationAsync({ workGroupId: workGroup.id, maruValuation: valuationToSave }));
    setIsShown(false);
  };

  const setValuation = (changedObject: any) => {
    const valuationToSet = { ...maruValuation, ...changedObject };
    setMaruValuation(valuationToSet);
  };

  return (
    <BhModal
      isShown={true}
      setIsShown={setIsShown}
      size="3xl"
      header={<h2>{"Osapoole " + workGroup.name + " hindamine"}</h2>}
      children={
        <BhScrollableBody>
          <div className="flex flex-col gap-y-2 p-8">
            <BhTextareaStackedLabel
              initialValue={maruValuation.task}
              property={"task"}
              label={"Töö nimetus"}
              onChangeCallback={setValuation}
              rows={5}
              labelHelper={`${t("INPUT.LABEL_HELPER.OPTIONAL_FIELD")}`}
            />
            <BhInputStackedLabel initialValue={maruValuation.contactname} property={"contactname"} label={"Alltöövõtja vastutav juht"} onChangeCallback={setValuation} required={true} />
            <BhDatepickerStackedLabel
              property={"projectstartdate"}
              initialValue={maruValuation.projectstartdate ? new Date(maruValuation.projectstartdate) : null}
              label={"Projekti algus"}
              onChangeCallback={setValuation}
              returnISOString={true}
              required={true}
            />
            <BhDatepickerStackedLabel
              property={"projectenddate"}
              initialValue={maruValuation.projectenddate ? new Date(maruValuation.projectenddate) : null}
              label={"Projekti lõpp"}
              onChangeCallback={setValuation}
              returnISOString={true}
              required={true}
            />
            <BhInputStackedLabel
              initialValue={maruValuation.taskpriceeur}
              property={"taskpriceeur"}
              label={"Töö maksumus"}
              onChangeCallback={setValuation}
              labelHelper={`${t("INPUT.LABEL_HELPER.OPTIONAL_FIELD")}`}
            />
            <BhStarRatingStackedLabel
              initialValue={maruValuation.qualityrate}
              property={"qualityrate"}
              label={"Töö kvaliteet"}
              onChangeCallback={setValuation}
              labelHelper={`${t("INPUT.LABEL_HELPER.OPTIONAL_FIELD")}`}
            />
            <BhTextareaStackedLabel
              initialValue={maruValuation.qualitycomment}
              property={"qualitycomment"}
              label={"Kommentaar"}
              onChangeCallback={setValuation}
              labelHelper={`${t("INPUT.LABEL_HELPER.OPTIONAL_FIELD")}`}
            />
            <BhStarRatingStackedLabel
              initialValue={maruValuation.termrate}
              property={"termrate"}
              label={"Tähtajad"}
              onChangeCallback={setValuation}
              labelHelper={`${t("INPUT.LABEL_HELPER.OPTIONAL_FIELD")}`}
            />
            <BhTextareaStackedLabel
              initialValue={maruValuation.termcomment}
              property={"termcomment"}
              label={"Kommentaar"}
              onChangeCallback={setValuation}
              labelHelper={`${t("INPUT.LABEL_HELPER.OPTIONAL_FIELD")}`}
            />
            <BhStarRatingStackedLabel
              initialValue={maruValuation.collaborationrate}
              property={"collaborationrate"}
              label={"Koostöö"}
              onChangeCallback={setValuation}
              labelHelper={`${t("INPUT.LABEL_HELPER.OPTIONAL_FIELD")}`}
            />
            <BhTextareaStackedLabel
              initialValue={maruValuation.collaborationcomment}
              property={"collaborationcomment"}
              label={"Kommentaar"}
              onChangeCallback={setValuation}
              labelHelper={`${t("INPUT.LABEL_HELPER.OPTIONAL_FIELD")}`}
            />
            <BhStarRatingStackedLabel
              initialValue={maruValuation.abilitiesrate}
              property={"abilitiesrate"}
              label={"Oskused"}
              onChangeCallback={setValuation}
              labelHelper={`${t("INPUT.LABEL_HELPER.OPTIONAL_FIELD")}`}
            />
            <BhTextareaStackedLabel
              initialValue={maruValuation.abilitiescomment}
              property={"abilitiescomment"}
              label={"Kommentaar"}
              onChangeCallback={setValuation}
              labelHelper={`${t("INPUT.LABEL_HELPER.OPTIONAL_FIELD")}`}
            />
            <BhStarRatingStackedLabel initialValue={valuationAverageScore} label={"Keskmine hinne"} allowFractions={true} readOnly={true} displayNumericValue={true} />
            <BhTextareaStackedLabel
              initialValue={maruValuation.averagecomment}
              property={"averagecomment"}
              label={"Kommentaar"}
              onChangeCallback={setValuation}
              labelHelper={`${t("INPUT.LABEL_HELPER.OPTIONAL_FIELD")}`}
            />
            <BhTextareaStackedLabel
              initialValue={maruValuation.comments}
              property={"comments"}
              label={"Üldine kommentaar"}
              onChangeCallback={setValuation}
              labelHelper={`${t("INPUT.LABEL_HELPER.OPTIONAL_FIELD")}`}
            />
          </div>
        </BhScrollableBody>
      }
      footer={
        <div>
          <BhTextOnlyButton buttonProps={{ onClick: () => setIsShown(false) }}>Sulge</BhTextOnlyButton>
          <BhPrimaryButton buttonProps={{ onClick: saveValuation, disabled: requiredFieldsUnfilled }}>Salvesta</BhPrimaryButton>
        </div>
      }
    />
  );
};

export default MaruPartyValuationModal;
