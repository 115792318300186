import React, { FC } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import BhTextareaSimple from "@components/input/BhTextareaSimple";
import BhInputLabel from "@components/input/BhInputLabel";

interface Props {
  initialValue: any;
  property: string;
  onBlurCallback?: Function;
  onChangeCallback?: Function;
  required?: boolean;
  leadingIcon?: IconProp;
  trailingIcon?: IconProp;
  type?: string;
  placeholder?: string | null;
  disabled?: boolean;
  inputClasses?: string;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  label?: string | null;
  labelHelper?: string;
  validationHelper?: string;
  rows?: number;
  trailingButton?: React.ReactElement;
  maxHeightClass?: string;
}

const BhTextareaStackedLabel: FC<Props> = ({
  property,
  initialValue,
  onBlurCallback,
  onChangeCallback,
  label,
  leadingIcon,
  trailingIcon,
  type,
  placeholder,
  validationHelper,
  disabled,
  inputClasses,
  labelHelper,
  inputRef,
  required,
  rows,
  trailingButton,
  maxHeightClass
}) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        {label && <BhInputLabel>{label}</BhInputLabel>}
        {labelHelper && (
          <div className="text-13px l-h-16px bh-text-deep-ocean-80 px-0.5 py-1.5 font-bold">
            <span className="bh-text-pigeon-70 font-normal italic"> - {labelHelper}</span>
          </div>
        )}
        {required && <span className="bh-text-error-red">*</span>}
      </div>
      <BhTextareaSimple
        property={property}
        initialValue={initialValue}
        onBlurCallback={onBlurCallback}
        onChangeCallback={onChangeCallback}
        type={type}
        placeholder={placeholder ? placeholder : label ? (disabled ? "" : label) : ""}
        leadingIcon={leadingIcon}
        trailingIcon={trailingIcon}
        disabled={disabled}
        inputClasses={inputClasses}
        inputRef={inputRef}
        required={required}
        rows={rows}
        trailingButton={trailingButton}
        maxHeightClass={maxHeightClass}
      />
      {validationHelper && <div className="l-h-14px bh-text-deep-ocean-80 px-0.5 text-sm">{validationHelper}</div>}
    </div>
  );
};

export default BhTextareaStackedLabel;
