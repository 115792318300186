import React, { FC, ReactElement, useCallback, useState } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  value: any;
  textarea?: boolean;
  className?: string;
  children: ReactElement;
  placeholder?: string;
  disabled?: boolean;
}

const BhFakeInput: FC<Props> = ({ value, textarea, className, children, placeholder, disabled }) => {
  const [isActive, setIsActive] = useState(false);

  const onClick = useCallback(() => {
    if (!disabled) {
      setIsActive(true);
    }
  }, []);

  if (!isActive) {
    return (
      <div
        onClick={onClick}
        className={classNames(
          className,
          textarea ? "mb-[4.5px] whitespace-pre-line" : "",
          !value && "bh-text-deep-ocean-40",
          disabled ? "bh-bg-smoke bh-border-smoke bh-text-deep-ocean-40" : "hover:bh-border-pigeon-70",
          "fake-input bh-border-pigeon-40 l-h-20 break-word py-7px min-h-[36px] w-full cursor-text overflow-hidden rounded border px-3"
        )}
      >
        {value ? value : placeholder ? <span className="bh-hover-item">{placeholder}</span> : ""}
      </div>
    );
  }
  return children;
};

export default React.memo(BhFakeInput);
