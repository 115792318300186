import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectAllTaskBoards } from "@/app/store/taskBoardsSlice";
import { selectCurrentUser } from "@/app/store/userSlice";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { selectCurrentFormName } from "@/app/store/form/formSlice";
import { selectProjectAdministrators, toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { createNewTaskObject, IFormTaskInfo } from "@/model/taskBoard/ITask";
import { selectDefaultProjectTaskCategory, setOpenedTaskInfo } from "@/app/store/tasksSlice";
import { ITaskBoard } from "@/model/taskBoard/ITaskBoard";
import { classNames } from "@/utilities/jsUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareKanban } from "@fortawesome/pro-regular-svg-icons/faSquareKanban";
import { isTaskPersonal } from "@/utilities/taskBoardUtil";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";

interface Props {
  disabled?: boolean;
  row: any;
  agendaFieldProperty: string;
  formTaskInfo: IFormTaskInfo;
  saveCallback: Function;
  removeCallback: Function;
}

const FormProtocolFieldTableTask: FC<Props> = ({ disabled, row, agendaFieldProperty, formTaskInfo, saveCallback, removeCallback }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const taskBoards = useAppSelector(selectAllTaskBoards);
  const formName = useAppSelector(selectCurrentFormName);
  const defaultTaskCategory = useAppSelector(selectDefaultProjectTaskCategory);
  const currentUser = useAppSelector(selectCurrentUser);
  const projectAdmins = useAppSelector(selectProjectAdministrators);
  const projectAdminIds = projectAdmins && projectAdmins.map((admin) => admin.userEntityId);
  const isPersonalTaskOfCurrentUser = row.task?.id && isTaskPersonal(row.task, currentUser);
  const isPersonalTaskBoardTask = row.task && row.task.id && !row.task.taskBoardId;
  const taskOpenDisabled = (isPersonalTaskBoardTask && !isPersonalTaskOfCurrentUser) || disabled;
  const dropdownValues =
    row.task && row.task.assignee
      ? [
          ...(row.task.assignee === currentUser.id ? [{ id: 0, name: t("WG.PERSONAL") }] : []),
          ...(projectAdminIds.includes(row.task.assignee)
            ? taskBoards
            : taskBoards.filter(
                (tb) => tb.users && tb.users.length > 0 && [...tb.users, { userEntityId: currentUser.id } as ISimpleValidUserAuthority].some((user) => user.userEntityId === row.task.assignee)
              ))
        ]
      : taskBoards;

  const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModal(
    () => (
      <BhDeleteConfirmationModal
        setIsOpen={hideDeleteConfirmationModal}
        header={<h2>{t("TASK.DELETE.CONFIRMATION.TITLE")}</h2>}
        body={<div dangerouslySetInnerHTML={{ __html: t("TASK.DELETE.CONFIRMATION.BODY") as any }} />}
        confirmationButtonText={t("GLOBAL.DELETE")}
        handleDelete={() => {
          removeCallback();
          hideDeleteConfirmationModal();
        }}
      />
    ),
    [removeCallback]
  );

  const saveTaskToTaskBoard = (taskBoard: ITaskBoard) => {
    const isPersonalTask = taskBoard.id === 0;
    let deadline;
    if (row.task && row.task.deadline) {
      deadline = new Date(row.task.deadline);
    } else {
      deadline = new Date();
      deadline.setDate(deadline.getDate() + 1);
      deadline.setHours(12);
      deadline.setMinutes(0);
    }
    const task = createNewTaskObject({
      isPersonalTask: isPersonalTask,
      currentUser: currentUser,
      predefinedName: formName + ", nr " + (row.nr || ""),
      predefinedDescription: row.content,
      taskBoardId: !isPersonalTask ? taskBoard.id : undefined,
      attachments: row.attachments,
      deadline: deadline,
      category: defaultTaskCategory,
      assignee: row.task?.assignee || null,
      assigneeFullName: row.task?.assigneeFullName || null,
      status: row.task?.status || null
    });
    saveCallback(task);
  };

  const openTaskModal = () => {
    if (!taskOpenDisabled && row.task && row.task.id) {
      dispatch(setOpenedTaskInfo({ id: row.task.id, disabled: disabled, formTask: { ...row.task, formTaskInfo: formTaskInfo } }));
      dispatch(toggleProjectModalsOpen({ modal: "projectTaskModal" }));
    }
  };

  if (row.task && row.task.id) {
    return (
      <div
        className={classNames(
          "bh-border-pigeon-40 hover:bh-border-pigeon-60 bh-bg-white group relative mt-0.5 flex h-8 w-full cursor-pointer flex-row items-center justify-between rounded border p-1 transition-all duration-300",
          taskOpenDisabled && "cursor-not-allowed"
        )}
        onClick={openTaskModal}
      >
        <div className="flex h-6 w-6 flex-row items-center justify-center rounded" style={{ backgroundColor: row.task?.category?.color }}>
          <div className="bh-text-white">
            <FontAwesomeIcon icon={faSquareKanban} />
          </div>
        </div>
        <div className="bh-text-deep-ocean-80">{isPersonalTaskBoardTask ? t("GLOBAL.PERSONAL_TASK").toUpperCase() : row.task.taskIdentifier}</div>
        <div className="absolute right-1 flex h-6 w-6 flex-col justify-center rounded opacity-0 group-hover:opacity-100">
          <div
            className="bh-bg-error-red-10 bh-text-error-red flex h-6 w-6 flex-col justify-center rounded"
            onClick={(event: any) => {
              event.stopPropagation();
              showDeleteConfirmationModal();
            }}
          >
            <FontAwesomeIcon icon={faTrash} size={"sm"} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <BhDropdown
        button={<BhTextOnlyButton buttonProps={{ classes: "bh-hover-item-opacity" }}>{t("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.TASKBOARD")}</BhTextOnlyButton>}
        menu={
          <BhDropdownMenu
            values={dropdownValues}
            textProperty={"name"}
            type={BhDropdownTypeEnum.STRING}
            onSelect={saveTaskToTaskBoard}
            widthClass={"w-fit whitespace-nowrap"}
            closeOnItemClick={true}
            translateValues={true}
          />
        }
        position={BhDropdownPositionEnum.BOTTOM_LEFT}
        disabled={disabled}
      />
    );
  }
};

export default FormProtocolFieldTableTask;
