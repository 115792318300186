import React, { FC } from "react";
import { IFormBaseVersionRow } from "@/model/form/IFormBaseVersionRow";
import { FormRowFieldType } from "@/model/form/IFormBaseVersionRowField";
import { classNames } from "@/utilities/jsUtilities";
import FormRowFieldContainer from "@components/form/FormRowFieldContainer";

interface Props {
  baseVersionRow: IFormBaseVersionRow;
  disabled: boolean;
  isPreview?: boolean;
  dataSaveCallback?: Function;
  nestedDataSaveCallback?: Function;
  nestedDataAddCallback?: Function;
  nestedDataRemoveCallback?: Function;
}

const FormRow: FC<Props> = ({ baseVersionRow, disabled, isPreview, nestedDataRemoveCallback, nestedDataSaveCallback, nestedDataAddCallback, dataSaveCallback }) => {
  const sortedFields = baseVersionRow.fields.slice().sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1));

  const fieldClassMap: Record<FormRowFieldType, string> = {
    ATTACHMENT: "w-full",
    DATE: "form-base-field-quarter-width",
    TABLE: "w-full",
    PEOPLE: "w-full",
    PROTOCOL: "w-full",
    SEPARATOR: "w-full",
    TEXT: "form-base-field-half-width",
    TIME: "form-base-field-quarter-width",
    WEATHER: "w-full",
    WYSIWYG: "w-full",
    MNT_MP_TABLE_FIELD: "w-full",
    TOOPLAAN_TABLE: "w-full",
    TEXT_EDITOR: "w-full",
    SCM_TABLE: "w-full",
    SCM_TASKS: "w-full",
    ATTACHMENTS_WITH_DATES: "w-full",
    UPLOAD: "w-full"
  };

  return (
    <div className="-mt-4 flex flex-row gap-x-2">
      {sortedFields &&
        sortedFields.length > 0 &&
        sortedFields.map((field, index) => (
          <div className={classNames("mt-2 flex w-full flex-row gap-x-2", fieldClassMap[field.fieldType])} key={index}>
            <FormRowFieldContainer
              field={field}
              disabled={disabled}
              isPreview={isPreview}
              nestedDataSaveCallback={nestedDataSaveCallback}
              dataSaveCallback={dataSaveCallback}
              nestedDataAddCallback={nestedDataAddCallback}
              nestedDataRemoveCallback={nestedDataRemoveCallback}
            />
          </div>
        ))}
    </div>
  );
};

export default FormRow;
