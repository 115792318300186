import React, { FC } from "react";
import BhDatePicker from "@components/input/BhDatePicker";
import BhInputLabel from "@components/input/BhInputLabel";

interface Props {
  initialValue?: any;
  property: string;
  onChangeCallback?: Function;
  disabled?: boolean;
  label?: string;
  labelHelper?: string;
  validationHelper?: string;
  classes?: string;
  returnISOString?: boolean;
  placeholder?: string;
  widthClasses?: string;
  onResetCallback?: Function;
  wrapperClasses?: string;
  iconVisible?: boolean;
  required?: boolean;
}

const BhDatepickerStackedLabel: FC<Props> = ({
  property,
  initialValue,
  label,
  validationHelper,
  disabled,
  labelHelper,
  onChangeCallback,
  classes,
  returnISOString,
  placeholder,
  widthClasses,
  onResetCallback,
  wrapperClasses,
  iconVisible,
  required
}) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        {label && <BhInputLabel>{label}</BhInputLabel>}
        {labelHelper && (
          <div className="text-13px l-h-16px bh-text-deep-ocean-80 px-0.5 py-1.5 font-bold">
            <span className="bh-text-pigeon-70 font-normal italic"> - {labelHelper}</span>
          </div>
        )}
        {required && <span className="bh-text-error-red">*</span>}
      </div>
      <BhDatePicker
        initialValue={initialValue}
        property={property}
        onChangeCallback={onChangeCallback}
        disabled={disabled}
        classes={classes}
        returnISOString={returnISOString}
        placeholder={placeholder}
        widthClasses={widthClasses}
        onResetCallback={onResetCallback}
        wrapperClasses={wrapperClasses}
        iconVisible={iconVisible}
      />
      {validationHelper && <div className="l-h-14px bh-text-deep-ocean-80 px-0.5 py-1.5 text-sm">{validationHelper}</div>}
    </div>
  );
};

export default BhDatepickerStackedLabel;
