import React, { Dispatch, FC, SetStateAction } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentUser, selectCurrentUserInfo } from "@/app/store/userSlice";
import { IFileEntity } from "@/model/files/IFileEntity";
import { signatureAdded } from "@/app/store/signaturesSlice";
import { getNumberAndPrefix } from "@/model/IUserInfo";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import ContainerSignModalBody from "@/views/home/project/detail/container/modals/ContainerSignModalBody";
import { selectCurrentProject } from "@/app/store/project/projectSlice";
import { Module } from "@/model/IProject";

interface Props {
  fileContainer: IFileEntity;
  setContainerSignModalShown: Dispatch<SetStateAction<boolean>>;
}

const ContainerSignModal: FC<Props> = ({ fileContainer, setContainerSignModalShown }) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const currentUserInfo = useAppSelector(selectCurrentUserInfo);
  const dispatch = useAppDispatch();
  const project = useAppSelector(selectCurrentProject);
  const projectHasEparakstsModule = project.modules.some((module) => module.module === Module.EPARAKSTS);
  const signingMethods = ["ID_CARD", "SMART_ID", "MOBILE_ID", ...(projectHasEparakstsModule ? ["EPARAKSTS"] : [])];

  const initialNumber = currentUserInfo && currentUserInfo.phoneNumber ? getNumberAndPrefix(currentUserInfo.phoneNumber) : { prefix: "+372", number: "" };

  return (
    <ContainerSignModalBody
      containerUuid={fileContainer.uuid}
      projectId={fileContainer.projectId}
      username={currentUser.username}
      initialNumber={initialNumber}
      initialPersonalCode={currentUserInfo.personalCode}
      setContainerSignModalShown={setContainerSignModalShown}
      onSignatureSuccess={(signatureHolder: ISignatureHolder) => dispatch(signatureAdded(signatureHolder))}
      signingMethods={signingMethods}

    />
  );
};

export default ContainerSignModal;
