import { EntityId } from "@reduxjs/toolkit";
import { FormRowFieldType, IFormBaseVersionRowField } from "@/model/form/IFormBaseVersionRowField";
import { v4 as uuidv4 } from "uuid";

export interface IFormBaseVersionRow {
  _id: string;
  formBaseVersionId: EntityId;
  orderNumber: number;
  fields: Array<IFormBaseVersionRowField>;
}

export function getDefaultFieldValues(fieldType: string, translate: any) {
  const weatherFieldTimeDefaultProperty = uuidv4();
  const fieldValueDefaults: Record<string, any> = {
    [FormRowFieldType.DATE]: { prefill: { enabled: true }, label: translate("FORMBUILDER.FORM.BASE.FIELD.DATE") },
    [FormRowFieldType.TIME]: { prefill: { enabled: true }, label: translate("FORMBUILDER.FORM.BASE.FIELD.TIME") },
    [FormRowFieldType.PROTOCOL]: {
      agenda: [{ level: 0, nr: 1 }],
      labels: {
        number: translate("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.NUMBER"),
        content: translate("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.CONTENT"),
        assignee: translate("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.ASSIGNEE"),
        deadline: translate("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.DEADLINE"),
        status: translate("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.STATUS"),
        taskBoard: translate("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.TASKBOARD")
      },
      statusLabels: {
        TODO: translate("TASK.TODO"),
        IN_PROGRESS: translate("TASK.IN_PROGRESS"),
        NEEDS_APPROVAL: translate("TASK.NEEDS_APPROVAL"),
        DONE: translate("TASK.DONE"),
        ARCHIVED: translate("TASK.ARCHIVED")
      }
    },
    [FormRowFieldType.PEOPLE]: {
      columns: {
        name: { enabled: true, inputLabel: translate("FORMBUILDER.FORM.BASE.FIELD.PEOPLE.NAME") },
        occupation: { enabled: false, inputLabel: translate("FORMBUILDER.FORM.BASE.FIELD.PEOPLE.OCCUPATION") },
        company: { enabled: false, inputLabel: translate("FORMBUILDER.FORM.BASE.FIELD.PEOPLE.COMPANY") },
        email: { enabled: false, inputLabel: translate("FORMBUILDER.FORM.BASE.FIELD.PEOPLE.EMAIL") },
        num: { enabled: false, inputLabel: translate("FORMBUILDER.FORM.BASE.FIELD.PEOPLE.NUM") },
        date: { enabled: false, inputLabel: translate("FORMBUILDER.FORM.BASE.FIELD.PEOPLE.DATE") },
        custom: { enabled: false, inputLabel: translate("FORMBUILDER.FORM.BASE.FIELD.PEOPLE.CUSTOM") },
        status: { enabled: false, inputLabel: translate("FORMBUILDER.FORM.BASE.FIELD.PEOPLE.STATUS") },
        isSigner: { enabled: false, inputLabel: translate("FORMBUILDER.FORM.BASE.FIELD.PEOPLE.SIGNER") }
      },
      prefilledPersons: [{ title: translate("FORMBUILDER.FORM.BASE.FIELD.PEOPLE.NAME") }],
      statusLabels: { ATTENDED: translate("GLOBAL.YES"), MISSED: translate("GLOBAL.NO"), SIGNER: translate("FORMBUILDER.PEOPLE.SIGNER.STATUS") }
    },
    [FormRowFieldType.WEATHER]: {
      columns: [
        { label: translate("FORMBUILDER.FORM.BASE.FIELD.WEATHER.TIME"), type: "TEXT", property: weatherFieldTimeDefaultProperty },
        { label: translate("FORMBUILDER.FORM.BASE.FIELD.WEATHER.TEMPERATURE"), type: "TEXT", property: uuidv4() },
        { label: translate("FORMBUILDER.FORM.BASE.FIELD.WEATHER.DRY"), type: "CHECKBOX", property: uuidv4() },
        { label: translate("FORMBUILDER.FORM.BASE.FIELD.WEATHER.CLOUDY"), type: "CHECKBOX", property: uuidv4() },
        { label: translate("FORMBUILDER.FORM.BASE.FIELD.WEATHER.RAIN"), type: "CHECKBOX", property: uuidv4() },
        { label: translate("FORMBUILDER.FORM.BASE.FIELD.WEATHER.HEAVY_RAIN"), type: "CHECKBOX", property: uuidv4() },
        { label: translate("FORMBUILDER.FORM.BASE.FIELD.WEATHER.SNOW"), type: "CHECKBOX", property: uuidv4() },
        { label: translate("FORMBUILDER.FORM.BASE.FIELD.WEATHER.WIND"), type: "TEXT", property: uuidv4() }
      ],
      rows: [
        { [weatherFieldTimeDefaultProperty]: "08:00" },
        { [weatherFieldTimeDefaultProperty]: "13:00" },
        { [weatherFieldTimeDefaultProperty]: "18:00" },
        { [weatherFieldTimeDefaultProperty]: "23:00" }
      ]
    },
    [FormRowFieldType.TABLE]: {
      columns: [
        { label: "", type: "TEXT", property: uuidv4() },
        { label: "", type: "TEXT", property: uuidv4() },
        { label: "", type: "TEXT", property: uuidv4() }
      ],
      rows: [{}]
    }
  };
  return fieldValueDefaults[fieldType];
}
