import { IFileEntity } from "@/model/files/IFileEntity";
import { EntityId } from "@reduxjs/toolkit";
import { IFormEditor } from "@/model/IFormEditor";
import { IFormBaseVersionRow } from "@/model/form/IFormBaseVersionRow";
import { IFormBase } from "@/model/form/IFormBase";

export interface IForm {
  id: EntityId;
  fileEntityId: EntityId;
  formType: FormType;
  name: string;
  locked: boolean;
  data: any;
  fileName: string;
  editors: Array<IFormEditor>;
  createdBy: string;
  updatedBy: string;
  created: Date;
  updated: Date;
  formBaseVersionId: EntityId;
}

export enum FormType {
  VCW_KAAMOS = "VCW_KAAMOS",
  SCM_KAAMOS = "SCM_KAAMOS",
  BWD_KAAMOS = "BWD_KAAMOS",
  VCW_NORDECON = "VCW_NORDECON",
  BWD_NORDECON = "BWD_NORDECON",
  BWD_NORDECON_SUB = "BWD_NORDECON_SUB",
  SCM_ASTLANDA = "SCM_ASTLANDA",
  ASFALTEERIMINE_NORDECON = "ASFALTEERIMINE_NORDECON",
  FREESIMINE_NORDECON = "FREESIMINE_NORDECON",
  KOOSOLEK_NORDECON = "KOOSOLEK_NORDECON",
  TOOPLAAN_NORDECON = "TOOPLAAN_NORDECON",
  KANDEVOIME_NORDECON = "KANDEVOIME_NORDECON",
  BETOONITOOD_NORDECON = "BETOONITOOD_NORDECON",
  STABILISEERIMINE_NORDECON = "STABILISEERIMINE_NORDECON",
  LOPPULEVAATUS_NORDECON = "LOPPULEVAATUS_NORDECON",
  ULEVAATUSE_LEIUD_NORDECON = "ULEVAATUSE_LEIUD_NORDECON",
  BWD_MERKO = "BWD_MERKO",
  KOOSOLEK_KULINVEST = "KOOSOLEK_KULINVEST",
  TIHEDUS_NORDECON = "TIHEDUS_NORDECON",
  TORUSTIKU_KATSETUS_NORDECON = "TORUSTIKU_KATSETUS_NORDECON",
  KONTROLLIDE_REGISTER_NORDECON = "KONTROLLIDE_REGISTER_NORDECON",
  RINGKAIGU_CHECKLIST_NORDECON = "RINGKAIGU_CHECKLIST_NORDECON",
  ULEANDMIS_VASTUVOTU_AKT_METROPOL = "ULEANDMIS_VASTUVOTU_AKT_METROPOL",
  BWD_MARU = "BWD_MARU",
  VCW_MARU = "VCW_MARU",
  SCM_MARU = "SCM_MARU",
  ULEANDMIS_VASTUVOTU_AKT_MARU = "ULEANDMIS_VASTUVOTU_AKT_MARU",
  KOOSOLEK_MARU = "KOOSOLEK_MARU",
  REGISTER_NORDECON = "REGISTER_NORDECON",
  SCM_NB = "SCM_NB",
  TTA_NB = "TTA_NB",
  TTA_NORDECON = "TTA_NORDECON",
  PROOVIVOTUPROTOKOLL_NORDECON = "PROOVIVOTUPROTOKOLL_NORDECON",
  PROOVIDE_KOONDTABEL_NORDECON = "PROOVIDE_KOONDTABEL_NORDECON",
  KATMATA_TOODE_AKT_NORDECON = "KATMATA_TOODE_AKT_NORDECON",
  DOKUMENTIDE_REGISTER_NORDECON = "DOKUMENTIDE_REGISTER_NORDECON",
  KTA_KIIKRI = "KTA_KIIKRI",
  BWD_MERKO_SUB = "BWD_MERKO_SUB",
  TOODE_MARKUSTE_TABEL_MERKO = "TOODE_MARKUSTE_TABEL_MERKO",
  KTA_MERKO = "KTA_MERKO",
  KTA_MERKO_KAKSKEELNE = "KTA_MERKO_KAKSKEELNE",
  LEPINGUTE_REGISTER_NORDECON = "LEPINGUTE_REGISTER_NORDECON",
  ULEANDMIS_VASTUVOTU_AKT_MARU_SUB = "ULEANDMIS_VASTUVOTU_AKT_MARU_SUB",
  ULEANDMIS_VASTUVOTU_AKT_MARU_CLIENT = "ULEANDMIS_VASTUVOTU_AKT_MARU_CLIENT",
  MATERJALIDE_KOOSKOLASTAMISE_VORM = "MATERJALIDE_KOOSKOLASTAMISE_VORM",
  KTA_METROPOL = "KTA_METROPOL",
  EHITUSPAEVIK_METROPOL = "EHITUSPAEVIK_METROPOL",
  SCM_EVENTUS = "SCM_EVENTUS",
  TOOPLAAN_NORDECON_PTV = "TOOPLAAN_NORDECON_PTV",
  LIIKLUSKORRALDUSPAEVIK_NCN = "LIIKLUSKORRALDUSPAEVIK_NCN",
  MENETLUS_KOKKULEPE_NORDECON = "MENETLUS_KOKKULEPE_NORDECON",
  KOKKULEPETE_REGISTER_NORDECON = "KOKKULEPETE_REGISTER_NORDECON",
  MNT_MP_PINNAS = "MNT_MP_PINNAS",
  MNT_MP_KRAAVID = "MNT_MP_KRAAVID",
  MNT_MP_MULD = "MNT_MP_MULD",
  MNT_MP_DREEN = "MNT_MP_DREEN",
  MNT_MP_ALUSED = "MNT_MP_ALUSED",
  MNT_MP_KATEND = "MNT_MP_KATEND",
  MNT_MP_TRUUP = "MNT_MP_TRUUP",
  MNT_MP_KANDEVOIME = "MNT_MP_KANDEVOIME",
  MNT_KTA = "MNT_KTA",
  MNT_TEOSTATUD_TOODE_AKT = "MNT_TEOSTATUD_TOODE_AKT",
  EHITUSPAEVIK_UUS = "EHITUSPAEVIK_UUS",
  KTA_UUS = "KTA_UUS",
  VCW = "VCW",
  SCM = "SCM",
  BWD = "BWD",
  KOOSOLEK = "KOOSOLEK",
  PROOVIVOTU_PROTOKOLL = "PROOVIVOTU_PROTOKOLL",
  BETONEERIMISE_PROTOKOLL = "BETONEERIMISE_PROTOKOLL",
  BETONEERIMISE_PROTOKOLL_KAKSKEELNE = "BETONEERIMISE_PROTOKOLL_KAKSKEELNE",
  KATSETUSE_PROTOKOLL = "KATSETUSE_PROTOKOLL",
  TOOPLAAN = "TOOPLAAN",
  ATV_LOPPULEVAATUSE_AKT_NCN = "ATV_LOPPULEVAATUSE_AKT_NCN",
  EHITUSOBJEKTI_TOOOHUTUSE_PLAAN_MARU = "EHITUSOBJEKTI_TOOOHUTUSE_PLAAN_MARU",
  KTA_EVENTUS = "KTA_EVENTUS",
  KOOSOLEK_LV = "KOOSOLEK_LV",
  RB_EHITUSPAEVIK = "RB_EHITUSPAEVIK",
  RB_BETOONITOODE_PROTOKOLL = "RB_BETOONITOODE_PROTOKOLL",
  RB_KTA = "RB_KTA",
  RB_TEOSTATUD_TOODE_AKT = "RB_TEOSTATUD_TOODE_AKT"
}

export enum SubcontractorContractFormType {
  ATV_CONTRACT_EMBACH = "ATV_CONTRACT_EMBACH",
  ATV_CONTRACT_NCN = "ATV_CONTRACT_NCN",
  ATV_LEPING_LUHIVORM_NCN = "ATV_LEPING_LUHIVORM_NCN",
  EHITUSE_TOOVOTULEPING_NCN = "EHITUSE_TOOVOTULEPING_NCN",
  LEPINGU_MUUDATUS_EMBACH = "LEPINGU_MUUDATUS_EMBACH",
  LEPINGU_MUUDATUS_NCN = "LEPINGU_MUUDATUS_NCN",
  LISA1_NCN = "LISA1_NCN",
  LISA1_TOODE_KIRJELDUS_EMBACH = "LISA1_TOODE_KIRJELDUS_EMBACH",
  PROJEKTEERIMISE_JA_EHITUSE_TOOVOTULEPING_NCN = "NORDECON.PROJEKTEERIMISE_JA_EHITUSE_TOOVOTULEPING_NCN",
  PROJEKTEERIMISE_LUHILEPING_NCN = "PROJEKTEERIMISE_LUHILEPING_NCN",
  PROJEKTEERIMISLEPING_EMBACH = "PROJEKTEERIMISLEPING_EMBACH",
  PROJEKTEERIMISLEPING_NCN = "PROJEKTEERIMISLEPING_NCN",
  PROJEKTEERIMISTOODE_ULEANDMISE_VASTUVOTMISE_AKT_NCN = "PROJEKTEERIMISTOODE_ULEANDMISE_VASTUVOTMISE_AKT_NCN",
  TARNELEPING_EMBACH = "TARNELEPING_EMBACH",
  TARNELEPING_NCN = "TARNELEPING_NCN",
  TARNELEPING_UUS_NCN = "TARNELEPING_UUS_NCN",
  TARNETE_TELLIMISKIRI_EMBACH = "TARNETE_TELLIMISKIRI_EMBACH",
  TARNETE_TELLIMISKIRI_NCN = "TARNETE_TELLIMISKIRI_NCN",
  TELLIMUSE_KINNITUS_NCN = "TELLIMUSE_KINNITUS_NCN",
  TOO_TELLIMISKIRI_EMBACH = "TOO_TELLIMISKIRI_EMBACH",
  TOO_TELLIMISKIRI_NCN = "TOO_TELLIMISKIRI_NCN",
  ULEANDMIS_VASTUVOTU_AKT_MARU_SUB = "ULEANDMIS_VASTUVOTU_AKT_MARU_SUB",
  TOOFRONDI_ULEANDMISE_VASTUVOTMISE_AKT_NCN = "TOOFRONDI_ULEANDMISE_VASTUVOTMISE_AKT_NCN"
}

export enum ClientContractFormType {
  ULEANDMIS_VASTUVOTU_AKT_MARU_CLIENT = "ULEANDMIS_VASTUVOTU_AKT_MARU_CLIENT"
}

export enum SubcontractorActFormType {
  SUBCONTRACTOR_ACT_MR = "SUBCONTRACTOR_ACT_MR",
  SUBCONTRACTOR_ACT_NORDECON = "SUBCONTRACTOR_ACT_NORDECON",
  SUBCONTRACTOR_ACT_RT = "SUBCONTRACTOR_ACT_RT",
  SUBCONTRACTOR_ACT_TYCOON = "SUBCONTRACTOR_ACT_TYCOON"
}

export enum ClientActFormType {
  CLIENT_ACT_NORDECON = "CLIENT_ACT_NORDECON",
  CLIENT_ACT_RT = "CLIENT_ACT_RT",
  CLIENT_ACT_TYCOON = "CLIENT_ACT_TYCOON",
  CLIENT_ACT_MR = "CLIENT_ACT_MR"
}

export const BauhubMntFormTypes = [
  FormType.MNT_MP_PINNAS,
  FormType.MNT_MP_KRAAVID,
  FormType.MNT_MP_MULD,
  FormType.MNT_MP_DREEN,
  FormType.MNT_MP_ALUSED,
  FormType.MNT_MP_KATEND,
  FormType.MNT_MP_TRUUP,
  FormType.MNT_MP_KANDEVOIME,
  FormType.MNT_KTA,
  FormType.MNT_TEOSTATUD_TOODE_AKT
];

export const BauhubFormTypes = [
  FormType.EHITUSPAEVIK_UUS,
  FormType.KTA_UUS,
  FormType.VCW,
  FormType.SCM,
  FormType.BWD,
  FormType.KOOSOLEK,
  FormType.KOOSOLEK_LV,
  FormType.PROOVIVOTU_PROTOKOLL,
  FormType.BETONEERIMISE_PROTOKOLL,
  FormType.KATSETUSE_PROTOKOLL,
  FormType.TOOPLAAN
];

export const ClientFormTypes = [
  FormType.EHITUSOBJEKTI_TOOOHUTUSE_PLAAN_MARU,
  FormType.KTA_EVENTUS,
  FormType.BETONEERIMISE_PROTOKOLL_KAKSKEELNE,
  FormType.RB_KTA,
  FormType.RB_EHITUSPAEVIK,
  FormType.RB_BETOONITOODE_PROTOKOLL,
  FormType.RB_TEOSTATUD_TOODE_AKT
];

export interface IFormDiff {
  id: EntityId;
  dataChanges: Array<any>;
  formType: FormType;
  name: string;
  updated: Date;
}

export interface IFormFieldImportDTO {
  projectId: EntityId;
  formType: FormType;
  formName: string;
  companyName: string;
  fieldData: string;
  formDate: Date;
  formId: EntityId;
  formFileEntityId: EntityId;
  documents: Array<IFileEntity>;
  fieldDataFromArray: Array<any>;
  added?: boolean;
}

export interface IFormImportInfo {
  projectId: EntityId;
  directoryId: EntityId;
  formId: EntityId;
  formType: string;
  field: string;
  isAttachmentsField?: boolean;
  dateField: string;
  since: string;
  until: string;
  folderIds?: Array<EntityId>;
}

export interface IFormTemplate {
  id: number;
  formType: FormType;
  rowType: string;
  orderNumber: number;
  property: string;
  value: string;
}

export interface IFormDTO {
  formId: number;
  form: IForm;
  formBase: IFormBase;
  formTemplates: Array<IFormTemplate>;
  rows: Array<IFormBaseVersionRow>;
}

export interface IFormDataSaveRequest {
  formId: EntityId;
  fileEntityId: EntityId;
  projectId: EntityId;
  changes: any;
  path?: string;
  index?: number;
  changedProperty: string;
  changedValue: any;
  changedObjectId: string;
  formUpdated: Date;
  formDataUpdatedByAnotherUser: any;
}

export enum FormFieldType {
  INPUT = "INPUT",
  INPUT_DISABLED = "INPUT_DISABLED",
  INPUT_USER = "INPUT_USER",
  INPUT_SHORT = "INPUT_SHORT",
  TEXTAREA = "TEXTAREA",
  TEXTAREA_MIDDLE = "TEXTAREA_MIDDLE",
  DATEPICKER = "DATEPICKER",
  SEPARATOR = "SEPARATOR",
  WEATHER = "WEATHER",
  FIELD_IMPORT = "FIELD_IMPORT",
  UPLOAD = "UPLOAD",
  ATTACHMENTS = "ATTACHMENTS",
  PARTICIPANTS = "PARTICIPANTS",
  CHECKBOX = "CHECKBOX",
  KOOSOLEK_TABLE = "KOOSOLEK_TABLE",
  HEADER_SEPARATOR = "HEADER_SEPARATOR",
  SUBHEADER = "SUBHEADER",
  SCM_TABLE = "SCM_TABLE",
  SCM_TASKS = "SCM_TASKS",
  TIMEPICKER = "TIMEPICKER",
  BETONEERIMISE_PROTOKOLL_TABLE = "BETONEERIMISE_PROTOKOLL_TABLE",
  BETONEERIMISE_PROTOKOLL_WEATHER = "BETONEERIMISE_PROTOKOLL_WEATHER",
  TOGGLE_VISIBILITY_DATE = "TOGGLE_VISIBILITY_DATE",
  TOGGLE_VISIBILITY_INPUT = "TOGGLE_VISIBILITY_INPUT",
  TOOPLAAN_TABLE = "TOOPLAAN_TABLE",
  MNT_MP_HEADER_FIELD = "MNT_MP_HEADER_FIELD",
  MNT_MP_FOOTER_FIELD = "MNT_MP_FOOTER_FIELD",
  MNT_MP_TABLE_FIELD = "MNT_MP_TABLE_FIELD",
  ATTACHMENTS_WITH_DATES = "ATTACHMENTS_WITH_DATES",
  BWD_WORKS_TABLE = "BWD_WORKS_TABLE",
  BWD_WORKERS_TABLE = "BWD_WORKERS_TABLE",
  TEXT_EDITOR = "TEXT_EDITOR"
}

export interface ISimpleForm {
  fileEntityId: EntityId;
  created: Date;
  name: string;
  number: string;
  data: any;
}
