import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { IFormTaskInfo, ITask } from "@/model/taskBoard/ITask";
import { saveFormTaskAsync, savePersonalFormTaskAsync, selectDefaultProjectTaskCategory } from "@/app/store/tasksSlice";
import FormProtocolFieldTableTask from "@components/form/fields/protocolField/protocolFieldTaskFields/FormProtocolFieldTableTask";
import { selectCurrentFormFileEntityId, selectCurrentFormId, selectCurrentFormName } from "@/app/store/form/formSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import FormProtocolFieldTaskAssignee from "@components/form/fields/protocolField/protocolFieldTaskFields/FormProtocolFieldTaskAssignee";
import FormProtocolFieldTaskDeadline from "@components/form/fields/protocolField/protocolFieldTaskFields/FormProtocolFieldTaskDeadline";
import FormProtocolFieldTaskStatus from "@components/form/fields/protocolField/protocolFieldTaskFields/FormProtocolFieldTaskStatus";

interface Props {
  row: any;
  saveCallback: Function;
  disabled: boolean;
  agendaFieldProperty: string;
}

const FormProtocolFieldTaskRelatedFields: FC<Props> = ({ row, disabled, saveCallback, agendaFieldProperty }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const defaultTaskCategory = useAppSelector(selectDefaultProjectTaskCategory);
  const formName = useAppSelector(selectCurrentFormName);
  const projectId = useAppSelector(selectCurrentProjectId);
  const formId = useAppSelector(selectCurrentFormId);
  const formFileEntityId = useAppSelector(selectCurrentFormFileEntityId);
  const formTaskInfo = { formId: formId, fileEntityId: formFileEntityId, projectId: projectId, formRow: row, changedProperty: "task", path: agendaFieldProperty } as IFormTaskInfo;

  const newFormTask = {
    name: formName + ", nr " + (row.nr || ""),
    category: defaultTaskCategory,
    description: row.content
  } as ITask;

  const saveTask = (changedObject: ITask) => {
    const taskToSave = { ...(row.task ? row.task : newFormTask), ...changedObject } as ITask;
    const taskBoardTaskExists = row.task && row.task.id;
    if (taskBoardTaskExists) {
      saveTaskToTaskBoard(taskToSave);
    } else {
      saveTaskToFormOnly(taskToSave);
    }
  };

  const saveTaskToFormOnly = (task: ITask) => {
    const taskToSave = { task: task };
    saveCallback(taskToSave, "task", row._id);
  };

  const saveTaskToTaskBoard = (task: ITask) => {
    const isPersonalTask = !task.taskBoardId;
    const taskToSave = { ...task, ...{ formTaskInfo: formTaskInfo } };
    if (isPersonalTask) {
      dispatch(savePersonalFormTaskAsync({ task: taskToSave, projectId: projectId }));
    } else {
      dispatch(saveFormTaskAsync({ task: taskToSave, projectId: projectId }));
    }
  };

  const removeTask = () => {
    if (row.task && row.task.id) {
      saveTaskToTaskBoard({ ...row.task, deleted: true } as ITask);
      saveTaskToFormOnly({} as ITask);
    }
  };

  return (
    <>
      <div className="w-56 min-w-56">
        <FormProtocolFieldTaskAssignee
          row={row}
          newFormTask={newFormTask}
          saveTaskToTaskBoard={saveTaskToTaskBoard}
          saveTaskToForm={saveTaskToFormOnly}
          disabled={disabled}
          placeholder={!row.task || !row.task.assigneeFullName ? (t("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.ASSIGNEE") as string) : ""}
        />
      </div>
      <div className="min-w-26 max-w-26 w-26">
        <FormProtocolFieldTaskDeadline
          row={row}
          disabled={disabled}
          saveCallback={saveTask}
          placeholder={!row.task || !row.task.deadline ? (t("FORMBUILDER.FORM.BASE.FIELD.PROTOCOL.DEADLINE") as string) : ""}
        />
      </div>
      <div className="flex w-24 min-w-24 flex-row items-center justify-center">
        <FormProtocolFieldTaskStatus row={row} disabled={disabled} saveCallback={saveTask} />
      </div>
      <div className="flex w-24 min-w-24 max-w-24 flex-row items-center justify-center">
        {!disabled && (
          <FormProtocolFieldTableTask
            disabled={disabled}
            row={row}
            agendaFieldProperty={agendaFieldProperty}
            formTaskInfo={formTaskInfo}
            saveCallback={saveTaskToTaskBoard}
            removeCallback={removeTask}
          />
        )}
      </div>
    </>
  );
};

export default FormProtocolFieldTaskRelatedFields;
