import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PublicNavbar from "@/views/home/navbar/PublicNavbar";
import { EntityId } from "@reduxjs/toolkit";
import { fetchPublicContainerByUuid, fetchPublicSignInviteByUuid } from "@/api/signInviteAPI";
import { IPublicContainer } from "@/model/container/IPublicContainer";
import { ISignInvite } from "@/model/ISignInvite";
import PublicContainerInfoHeader from "@/views/home/project/detail/container/public/PublicContainerInfoHeader";
import PublicContainerHeader from "@/views/home/project/detail/container/public/PublicContainerHeader";
import PublicContainerFileList from "@/views/home/project/detail/container/public/PublicContainerFileList";
import BhLargeText from "@components/text/BhLargeText";
import PublicContainerSignaturesList from "@/views/home/project/detail/container/public/PublicContainerSignaturesList";
import ContainerDeclineModal from "@/views/home/project/detail/container/modals/ContainerDeclineModal";
import { ISignatureHolder } from "@/model/ISignatureHolder";
import dayjs from "dayjs";
import PublicContainerTimeLeft from "@/views/home/project/detail/container/public/PublicContainerTimeLeft";
import ContainerSignModalBody from "@/views/home/project/detail/container/modals/ContainerSignModalBody";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { urlParamsToObject } from "@/utilities/jsUtilities";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "@/app/hooks";

interface Props {
  containerUuid: string;
  signInviteUuid: string;
  projectId: EntityId;
}

const PublicContainer: FC<Props> = ({ containerUuid, signInviteUuid, projectId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [signInvite, setSignInvite] = useState<ISignInvite | undefined>();
  const [publicContainer, setPublicContainer] = useState<IPublicContainer | undefined>();
  const [containerSignModalShown, setContainerSignModalShown] = useState(false);
  const [containerDeclineModalShown, setContainerDeclineModalShown] = useState(false);
  const location = useLocation();
  const { failed } = urlParamsToObject(location.search);

  const refreshContainerAndSignInvite = () => {
    fetchPublicSignInviteByUuid(containerUuid, signInviteUuid, projectId).then((signInvite) => {
      setSignInvite(signInvite);
    });
    fetchPublicContainerByUuid(containerUuid, signInviteUuid, projectId).then((result) => {
      setPublicContainer(result);
      if (failed === "true") {
        dispatch(
          toastFlagAdded({
            id: uuidv4(),
            type: BauhubBannerType.ERROR,
            disappear: true,
            translateCode: "SIGNING.FAILED"
          })
        );
      }
    });
  };

  useEffect(() => {
    refreshContainerAndSignInvite();
  }, []);

  const isSignedOrDeclined = signInvite && (signInvite?.signed || signInvite?.declined);
  const hourAfterSignedOrDeclined = isSignedOrDeclined && dayjs(signInvite.signedOrDeclinedTime).add(1, "hour");

  return (
    <div className="flex h-screen w-screen flex-col overflow-hidden">
      <div>
        <PublicNavbar />
        {signInvite && publicContainer && (
          <PublicContainerHeader
            signInvite={signInvite}
            containerUuid={containerUuid}
            signInviteUuid={signInviteUuid}
            setContainerDeclineModalShown={setContainerDeclineModalShown}
            setContainerSignModalShown={setContainerSignModalShown}
          />
        )}
      </div>
      {signInvite && publicContainer && (
        <div className="flex flex-1 flex-col overflow-auto">
          <PublicContainerInfoHeader containerName={publicContainer?.fileName} />
          <div className="mx-auto flex w-full max-w-[1366px] flex-col p-6">
            {isSignedOrDeclined && hourAfterSignedOrDeclined && <PublicContainerTimeLeft expiresAt={hourAfterSignedOrDeclined} />}
            {publicContainer?.signatures && publicContainer?.signatures.length > 0 && <h3 className="inline-block py-3">{t("CONTAINER.SIGNATURES.HEADER")}</h3>}
            <div className="flex min-h-[120px] w-full pb-12">
              <PublicContainerSignaturesList publicContainer={publicContainer} />
            </div>
            <div>
              {publicContainer?.files && publicContainer?.files.length < 1 && <BhLargeText classes="text-center pt-10 items-center">{t("SHAREBOX.NOFILES.HEADER") as string}</BhLargeText>}
              {publicContainer?.files && publicContainer?.files.length > 0 && (
                <PublicContainerFileList containerUuid={containerUuid} signInviteUuid={signInviteUuid} publicContainer={publicContainer} />
              )}
            </div>
          </div>
        </div>
      )}
      {/*MODALS*/}
      {containerSignModalShown && signInvite && publicContainer && (
        <ContainerSignModalBody
          containerUuid={containerUuid}
          signInviteUuid={signInviteUuid}
          projectId={signInvite?.projectId}
          username={signInvite?.username}
          initialNumber={{ prefix: "+372", number: "" }}
          initialPersonalCode={""}
          setContainerSignModalShown={setContainerSignModalShown}
          onSignatureSuccess={refreshContainerAndSignInvite}
          isPublicSigning={true}
          signingMethods={publicContainer.signingMethods}
        />
      )}
      {containerDeclineModalShown && (
        <ContainerDeclineModal
          publicSignInviteToDecline={{ username: signInvite?.username, containerUuid: signInvite?.containerUuid } as ISignatureHolder}
          setContainerDeclineModalShown={setContainerDeclineModalShown}
          callbackAfterDeclineSuccess={refreshContainerAndSignInvite}
        />
      )}
    </div>
  );
};

export default PublicContainer;
