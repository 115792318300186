import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boRemoveFormAsync, boSaveCustomFormAsync, boSelectCompany } from "@/app/store/backofficeSlice";
import BhCombobox from "@components/input/BhCombobox";
import BOCompanyTemplateRow from "@components/backoffice/company/modulesAndTemplates/BOCompanyTemplateRow";
import { IEnabledForm } from "@/model/ICompany";
import { BauhubFormTypes, BauhubMntFormTypes, ClientFormTypes, FormType } from "@/model/IForm";
import { useTranslation } from "react-i18next";
import { naturalSortString } from "@/utilities/sortUtilities";
import { boDeleteFormBuilderFormAsync, boSaveFormBuilderFormAsync, fetchFormBasesForCompanyAsync, selectCompanyFormBases } from "@/app/store/form/formBasesSlice";
import { IFormBase } from "@/model/form/IFormBase";

const BOCompanyForms = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const company = useAppSelector(boSelectCompany);
  const companyFormBuilderForms = useAppSelector(selectCompanyFormBases);
  const companyCustomForms =
    company.enabledForms
      ?.filter((form) => form.type === null && !companyFormBuilderForms.some((fb) => fb.type === form.code))
      .map((fb) => {
        return {
          type: fb.code,
          id: fb.id,
          createdBy: "custom"
        } as IFormBase;
      }) || [];
  const allForms = Object.values(FormType);
  const companyForms = [...companyFormBuilderForms, ...companyCustomForms].sort((a, b) => {
    const aType = allForms.some((formType) => formType === a.type) ? t("FORMS." + a.type) : a.type;
    const bType = allForms.some((formType) => formType === b.type) ? t("FORMS." + b.type) : b.type;
    return aType > bType ? 1 : aType < bType ? -1 : 0;
  });
  const formBuilderForms = [...BauhubMntFormTypes, ...BauhubFormTypes, ...ClientFormTypes];
  const filteredForms = allForms.filter((form) => !companyForms.some((companyForm) => form === companyForm.type));

  useEffect(() => {
    if (company && company.id) {
      dispatch(fetchFormBasesForCompanyAsync(company.id));
    }
  }, [company.id]);

  const formTranslationMap: Record<string, string> = {};

  const translatedForms = filteredForms
    .map((form) => {
      const translatedForm = t("FORMS." + form);
      formTranslationMap[translatedForm] = form;
      return translatedForm;
    })
    .sort((a, b) => naturalSortString(a, b));

  const removeForm = (form: IFormBase) => {
    const isFormBuilderForm = form.formBaseVersions && form.formBaseVersions.length > 0;
    if (isFormBuilderForm) {
      dispatch(boDeleteFormBuilderFormAsync({ companyId: company.id, formBaseId: form.id }));
    }
    if (!isFormBuilderForm) {
      dispatch(boRemoveFormAsync({ id: form.id } as IEnabledForm));
    }
  };

  const saveForm = (selectedValue: Record<string, FormType>) => {
    const newFormType = formTranslationMap[selectedValue.code];
    const isFormBuilderForm = formBuilderForms.some((formType) => formType === newFormType);
    const isOldCustomForm = !formBuilderForms.some((formType) => formType === newFormType);
    if (isFormBuilderForm) {
      const newFormBase = { type: newFormType } as IFormBase;
      dispatch(boSaveFormBuilderFormAsync({ companyId: company.id, formBase: newFormBase }));
    }
    if (isOldCustomForm) {
      const dto = { code: newFormType, nameLabel: newFormType, companyId: company.id };
      dispatch(boSaveCustomFormAsync(dto as IEnabledForm));
    }
  };

  return (
    <div id="forms" className="w-full">
      <h2>Forms</h2>
      {companyForms?.map((form) => (
        <BOCompanyTemplateRow
          key={form.id}
          template={form}
          nameProperty="code"
          removeCallback={removeForm}
          label={allForms.some((formType) => formType === form.type) ? t("FORMS." + form.type) : form.type}
        />
      ))}
      <div className="mt-2 w-full">
        <BhCombobox initialValue={""} values={translatedForms} property="code" placeholder="+ Add module" onSelect={saveForm} enableNewValue={true} />
      </div>
    </div>
  );
};

export default BOCompanyForms;
