import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { getDefaultFieldValues, IFormBaseVersionRow } from "@/model/form/IFormBaseVersionRow";
import { FormRowFieldType, IFormBaseVersionRowField } from "@/model/form/IFormBaseVersionRowField";
import { useAppDispatch } from "@/app/hooks";
import { removeFieldFromBaseVersionRowAsync, saveFormBaseVersionRowWithNewFieldAsync } from "@/app/store/form/formBaseRowsSlice";
import FormBaseRowAddButton from "@components/form/base/body/FormBaseRowAddButton";
import FormBaseRowFieldContainer from "@components/form/base/body/fields/FormBaseRowFieldContainer";
import FormBaseFieldAddButton from "@components/form/base/body/FormBaseFieldAddButton";
import { classNames } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";

interface Props {
  companyId: EntityId;
  formBaseId: EntityId;
  formBaseVersionId: EntityId;
  baseVersionRow: IFormBaseVersionRow;
  saveNewRowCallback: Function;
  removeRowCallback: Function;
  disabled?: boolean;
  projectId?: EntityId;
}

const FormBaseRow: FC<Props> = ({ companyId, formBaseId, formBaseVersionId, baseVersionRow, disabled, projectId, saveNewRowCallback, removeRowCallback }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sortedFields = baseVersionRow.fields.slice().sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1));

  const saveRowWithNewField = (fieldType: string, previousField: IFormBaseVersionRowField) => {
    const newField = {
      formBaseVersionId: formBaseVersionId,
      fieldType: fieldType,
      orderNumber: previousField.orderNumber + 1,
      value: getDefaultFieldValues(fieldType, t) || {},
      newField: true
    } as IFormBaseVersionRowField;
    const rowToSave = { ...baseVersionRow, fields: [...sortedFields, newField] };
    dispatch(saveFormBaseVersionRowWithNewFieldAsync({ companyId: companyId, formBaseId: formBaseId, formBaseVersionRow: rowToSave }));
  };

  const removeFieldFromRow = (removedField: IFormBaseVersionRowField) => {
    const rowToSave = {
      ...baseVersionRow,
      fields: sortedFields.map((field) => {
        if (field.orderNumber === removedField.orderNumber) {
          return { ...field, removedField: true };
        }
        return field;
      })
    };
    const wasLastFieldRemovedFromRow = rowToSave.fields.filter((f) => !f.removedField).length === 0;
    if (wasLastFieldRemovedFromRow) {
      removeRowCallback(rowToSave);
    } else {
      dispatch(removeFieldFromBaseVersionRowAsync({ companyId: companyId, formBaseId: formBaseId, formBaseVersionRow: rowToSave }));
    }
  };

  const fieldClassMap: Record<FormRowFieldType, string> = {
    ATTACHMENT: "w-full",
    DATE: "form-base-field-quarter-width",
    TABLE: "w-full",
    PEOPLE: "w-full",
    PROTOCOL: "w-full",
    SEPARATOR: "w-full",
    TEXT: "form-base-field-half-width",
    TIME: "form-base-field-quarter-width",
    WEATHER: "w-full",
    WYSIWYG: "w-full",
    MNT_MP_TABLE_FIELD: "w-full",
    TOOPLAAN_TABLE: "w-full",
    TEXT_EDITOR: "w-full",
    SCM_TABLE: "w-full",
    SCM_TASKS: "w-full",
    ATTACHMENTS_WITH_DATES: "w-full",
    UPLOAD: "w-full"
  };

  const fieldTypesCurrentlyAdded = sortedFields.map((field) => field.fieldType);
  const allowMultipleFields = [FormRowFieldType.TEXT, FormRowFieldType.DATE, FormRowFieldType.TIME].some((type) => fieldTypesCurrentlyAdded.includes(type));
  const isFieldAddingDisabled = sortedFields.length === (allowMultipleFields ? 5 : 1);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        {sortedFields &&
          sortedFields.length > 0 &&
          sortedFields.map((field, index) => (
            <div className={classNames("mt-2 flex w-full flex-row gap-x-2", fieldClassMap[field.fieldType], index !== sortedFields.length - 1 && "mr-2")} key={index}>
              <FormBaseRowFieldContainer
                field={field}
                removeFieldCallback={removeFieldFromRow}
                disabled={disabled}
                companyId={companyId}
                formBaseId={formBaseId}
                formBaseVersionId={formBaseVersionId}
              />
              {!disabled && !isFieldAddingDisabled && <FormBaseFieldAddButton saveNewFieldCallback={saveRowWithNewField} previousField={field} />}
            </div>
          ))}
      </div>
      {!disabled && <FormBaseRowAddButton formBaseVersionId={formBaseVersionId} saveNewRowCallback={saveNewRowCallback} previousRow={baseVersionRow} />}
    </div>
  );
};

export default FormBaseRow;
